import React from 'react'
import { navigate } from "gatsby";

import {
  Seo,
  Zoom,
} from '@components'
import { 
  RightSidebar, 
  WebinarIcon, 
  PinningImage, 
  MitmAttackImage,
  DynamicCertPinningBanner
} from '../../../components/site/product';
import { PageLayout } from '../../../components/site/layout/page-layout';

import { BenefitCard } from "../../../components/core/benefit-card";
import { ProjectDataIcon } from '../../../components/core/icons/protect_data'
import { MitMIcon } from '../../../components/core/icons/mitm'
import { RocketIcon } from '../../../components/core/icons/rocket'

const DynamicCertPinningPage = ({ location }) => {
  return (
    <PageLayout pathname={location.pathname}>
      <DynamicCertPinningBanner title="Dynamic Certificate Pinning"/>
      
      <div className="container pt-[6rem] pb-[70px] text-[#262727]">
        <div className="w-full flex flex-col lg:flex-row px-[32px] gap-[38px]">
          {/* Left Side */}
          <div className="w-full">
              <div className="flex flex-col px-[20px] md:px-[28px] py-[30px] md:py-[40px] items-start gap-[15px] rounded-[10px] bg-[#F2F5F9]">
                  <h2 className="text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">
                    Dynamic Certificate Pinning
                  </h2>
                  <div className="w-full xs:w-[230px] h-[4px] bg-[#B21C7A]"></div>
                  <p className="text-[17px] leading-[29px] m-0">              
                    Dynamic Certificate Pinning is a technique used to enhance the security of a mobile app by ensuring that it only communicates with a trusted server whose digital certificate matches a predefined set of certificates. It protects the app against Man-in-the-Middle (MitM) attacks. Dynamic updates allow the app to handle changes certificates without requiring a code update.
                  </p>
              </div>

              <h2 className="mt-[50px] text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">Benefits</h2>
              <BenefitCard 
                className="mt-[20px]"
                Icon={
                  <ProjectDataIcon className="w-[55px] h-[55px] flex-shrink-0" />
                }
                title="Protect Your Customer Data"
                text="Protect your customer data and prevent reverse engineering of app traffic"
              />

              <div className="mt-[25px] w-full flex flex-col md:flex-row gap-[18px]">
                <BenefitCard 
                  className="md:w-1/2"
                  Icon={
                    <MitMIcon className="w-[55px] h-[55px] flex-shrink-0" />
                  }
                  title="Block MitM"
                  text="Block all Man-in-the-Middle (MitM) attacks"
                />
                <BenefitCard 
                  className="md:w-1/2"
                  Icon={
                    <RocketIcon className="w-[55px] h-[55px] flex-shrink-0" />
                  }
                  title="Dynamically Update All Your Apps"
                  text="Change pins as needed and dynamically update all your apps immediately"
                />
              </div>
              <div className="w-full flex justify-center mt-[40px]">
                <button className="text-[16px] text-[#fff] font-medium leading-[28px] bg-[#78A65A] px-[38px] py-[16px] rounded-[7px]"
                  onClick={() => {
                    navigate('https://approov.io/info/schedule-a-demo')
                  }}
                >
                  Request a Demo
                </button>
              </div>

              <h2 className="mt-[80px] text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">Man-in-the-Middle (MitM) Attacks</h2>
              <p className="mt-[20px] mb-[30px] text-[17px] leading-[29px]">
                Modern apps communicate using a secure TLS connection between the app and the backend APIs. A common mistake is to believe that this encryption makes sure that the data is not vulnerable to eavesdropping by a Man-in-the-Middle (MitM) attacker. Although TLS ensures that the data is encrypted end-to-end, it can’t be certain that one of the ends is actually your app.
              </p>
              <p className="mt-[20px] mb-[30px] text-[17px] leading-[29px]">
                It is possible to trick the app into allowing communication indirectly via an MitM attacker that can observe and potentially modify all of the traffic. Such MitM approaches are commonly used by attackers to reverse engineer an API protocol and extract secrets and API keys for subsequent attack.
              </p>
              <Zoom>{MitmAttackImage}</Zoom>

              <h2 className="mt-[80px] text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">How can You Prevent MitM Attacks?</h2>
              <p className="mt-[20px] mb-[30px] text-[17px] leading-[29px]">
                See why MitM attacks are a particular issue for mobile apps, and understand the techniques used by hackers. See how certificate pinning can help thwart mobile MitM attacks and how dynamic pinning can streamline mobile app devops.
              </p>
              <p className="mt-[20px] mb-[0px] text-[17px] leading-[29px]">
                Download the white paper today to understand the MitM threat to mobile apps and the steps you must take to enhance your security and protect your organization’s data and revenue from being hacked.
              </p>
              <div className="w-full flex justify-center mt-[40px]">
                <button className="text-[16px] text-[#fff] font-medium leading-[28px] bg-[#78A65A] px-[38px] py-[16px] rounded-[7px]"
                  onClick={() => {
                    navigate('https://approov.io/for/mitm-whitepaper/')
                  }}
                >
                  READ WHITEPAPER
                </button>
              </div>

              <h2 className="mt-[40px] text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">MitM Webinar</h2>
              <div className='flex flex-col sm:flex-row items-center sm:items-start mt-[20px] mb-[0px] gap-[4px]'>
                <div className="w-full md:w-[70%]">
                  <p className="m-0 text-[17px] leading-[29px]">      
                    This webinar recording shows why MitM attacks are a particular issue for mobile apps, providing an in-depth analysis of the problem and the techniques used by hackers - and how to stop them.
                  </p>
                </div>
                <div className='w-[80%] md:w-[30%] text-center'>
                  {WebinarIcon}
                </div>
              </div>
              <div className="w-full flex justify-center mt-[40px]">
                <button className="text-[16px] text-[#fff] font-medium leading-[28px] bg-[#78A65A] px-[38px] py-[16px] rounded-[7px]"
                  onClick={() => {
                    navigate('https://approov.io/for/mitm-webinar/watch/')
                  }}
                >
                  WATCH WEBINAR
                </button>
              </div>

              <h2 className="mt-[40px] text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">Certificate Pinning Simplified and Secure</h2>
              <p className="mt-[20px] mb-[30px] text-[17px] leading-[29px]">
                Approov provides support for updating pins dynamically over-the-air with no need to update the app. Certificate rotations can be handled instantly and cleanly, with no risk of interruption to customer service. Our frontend Quickstarts implement dynamic pinning across a wide range of different development frameworks.
              </p>
              <Zoom>{PinningImage}</Zoom>
              <p className="mt-[20px] mb-[0px] text-[17px] leading-[29px]">
                When it is not possible to perform a Man-in-the-Middle (MitM) attack by subverting the trusted certificates on a device, attackers turn to other techniques. Sometimes referred to as “Man-in-the-App” attacks, function-hooking frameworks (such as Frida) are used to nullify pinning implementations so that attacker certificates are accepted. Since Approov detects hooking frameworks, valid Approov tokens or Runtime Secrets are never issued in such cases, stopping attacks because the backend APIs will not respond.
              </p>

              {/* <div className="mt-[70px] flex justify-center px-[90px] py-[40px] items-start gap-[15px] rounded-[10px] bg-[#F2F5F9]">
                  <h2 className="text-center text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">
                    Recommended Resources About Approov Mobile RASP Security
                  </h2>
              </div> */}
          </div>
          {/* Right Side */}
          <RightSidebar location={location.pathname} />
        </div>
      </div>
    </PageLayout>
  )
}

export default DynamicCertPinningPage
